<template>
  <label :id="`${props.for}-label`" :for="props.for" class="modern-color-theme font-poppins text-sm leading-6 font-medium text-neutral-950">
    <slot />
  </label>
</template>
<script setup lang="ts">
const props = defineProps<{
  for: string
}>()

defineOptions({
  name: 'VSLabel'
})
</script>