import { ref } from "vue"
import { type Color, type ColorShade, type ColorWithShade } from "./types"

export function useElementId (id: string | undefined) {
  return ref(id || `el-${window.crypto.randomUUID()}`)
}

const createCSSColorVariable = (color: string, shade?: number) => `var(--avv-${color}-${shade ? '' : '-color'}${shade || ''})`

export function convertColorClassToCSS (color: ColorWithShade | undefined, defaultShade?: ColorShade) {
  if (color === 'white' || color === 'black') return color
  else if (color) {
    const [colorName, shade] = color.split('/') as [Color, ColorShade | undefined]

    return createCSSColorVariable(colorName, shade ?? defaultShade)
  }
}
